import service from '@/middleware'

const engineStateApi = {
  async getEngineStateAll (sector, activities, types) {
    const body = {
      sector,
      activities,
      types
    }
    const result = await service.http.post('/api/engine-state/all', body)
    return result
  }
}

export default engineStateApi
