import i18n from '@/i18n'

export default {
  name: 'StateCardComponent',
  components: {
  },
  props: {
    name: {
      type: String,
      required: true
    },
    rain: {
      type: Boolean,
      required: false,
      default: null
    },
    temperature: {
      type: Number,
      required: false,
      default: null
    },
    driveUnitName: {
      type: String,
      required: false,
      default: null
    },
    distance: {
      type: Number,
      required: false,
      default: null
    },
    stateName: {
      type: String,
      required: false,
      default: () => { return i18n.t('stateCards.stateless') }
    },
    stateColor: {
      type: String,
      required: false,
      default: '#78909C'
    },
    alert: {
      type: String,
      required: false,
      default: () => { return i18n.t('stateCards.noAlerts') }
    }
  },
  data: () => ({
    thermometerOptions: {
      text: {
        color: 'black',
        fontSize: 10,
        textAdjustmentY: 2,
        fontFamily: 'Arial',
        textEnabled: true
      },
      thermo: {
        color: '#FF0000',
        backgroundColor: '#fcf9f9',
        frameColor: 'black',
        ticks: 5,
        ticksEnabled: true,
        tickColor: 'black',
        tickWidth: '1'
      },
      layout: {
        height: 100,
        width: 80
      }
    }
  }),
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  created () {
  },
  beforeDestroy () {
  },
  computed: {
    /**
     * Para los termómetros de las tarjetas sin datos de temperatura
     */
    noTemperatureThermometerOptions () {
      const clone = { ...this.thermometerOptions }
      clone.thermo.color = '#9E9E9E'
      clone.text.color = '#9E9E9E'
      return clone
    },
    /**
     * Label de temperatura
     */
    temperatureLabel () {
      if (this.temperature && !isNaN(this.temperature)) {
        return `${this.temperature} ºC`
      } else {
        return this.$t('stateCards.noData')
      }
    }
  },
  methods: {
    /**
     * Para verificar si un valor es null o undefined
     * @param {*} value
     */
    checkUndefined (value) {
      const result = value == null
      return result
    }
  },
  watch: {
  }
}
