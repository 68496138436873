import StateCardContainer from '@/components/commons/state-card/container/StateCardContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import activityApi from '@/api/activity.api'
import thingApi from '@/api/thing.api'
import workFrontsStateApi from '@/api/workFrontsState.api'
import { clone } from 'ramda'
import { configurationService } from '@/business/configurationService'

// constante de update (en minutos)
const REFRESH = 10

export default {
  name: 'WorkFrontsStateComponent',
  components: {
    StateCardContainer
  },
  props: {
    _id: {
      type: String,
      required: true
    },
    sector: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    report: 'WORK_FRONTS',
    // datos de los filtros
    types: [],
    activities: [],
    workFrontsData: [],
    workFrontMainThings: [],
    // arreglo de arreglos de datos (fincas, frentes de trabajo, máquinas, rrhh)
    selectorsData: [[], []],
    // arreglo de los valores seleccionados
    selectorsModel: [null, null],
    loading: false,
    stateCardContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [],
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: null,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'type',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      selectorData: [],
      selectorModel: null,
      disableSaveButton: false,
      tab: null
    },
    // arreglo de "páginas" para los tabs del contenedor de tarjetas
    pages: [],
    // arreglo de páginas filtradas
    filteredPages: [],
    // índice de la página actual
    tabIndex: 0
  }),
  /**
   * Se cargan los datos del selector y, si corresponde, se cargan las tarjetas
   */
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    let initialLoad = true
    this.loading = true

    // actividades
    const activityResponse = await activityApi.getAllForSelector(this.sector, this.report)
    if (Array.isArray(activityResponse.data)) {
      this.stateCardContainerModel.filters[0].data.cleanAndUpdate(activityResponse.data)
      if (activityResponse.data.length === 1 && activityResponse.data[0].key === 'NO_ACTIVITY') {
        this.stateCardContainerModel.filters[0].selectedData = this.stateCardContainerModel.filters[0].data.filter(s => s.key === 'NO_ACTIVITY')
        this.stateCardContainerModel.filters[0].disabled = true
        this.stateCardContainerModel.filters[0].hide = true
        await this.activityFilterCallback()
      }
    }

    // llamado a callback de actividades
    this.stateCardContainerModel.filters[0].selectAction = this.activityFilterCallback.bind(this)

    // configuración
    await this.getConfiguration(initialLoad)

    this.loading = false
    // ejecuto la validación del selector, si es correcta, se cargan las tarjetas, sino abro el selector
    if (initialLoad) {
      // obtengo los datos de las tarjetas
      this.getData(this.stateCardContainerModel)
    } else {
      this.stateCardContainerModel.showNavigationPanel = true
    }
  },
  /**
   * Antes de que se destruya el componente, se elimina el setInterval de los datos
   */
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  computed: {
    /**
     * Detalles de las tarjetas (html)
     */
    details () {
      const activitiesName = this.activities.map(a => a.name)
      const typesName = this.types.map(t => t.name).toString()
      let level = this.pages.length > 0 ? this.pages[this.tabIndex].name : ''
      const selected = this.stateCardContainerModel.selectorModel
        ? this.stateCardContainerModel.selectorData.find(element => element.id === this.stateCardContainerModel.selectorModel)
        : undefined
      if (selected) {
        level += ` (${selected.name})`
      }
      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('dashboard.workFronts')}</h3>`
      details += `<h5>${this.$t('level')}: ${level}</h5>`
      if (this.stateCardContainerModel.filters && this.stateCardContainerModel.filters[0].data[0] && this.stateCardContainerModel.filters[0].data[0].key !== 'NO_ACTIVITY') {
        details += `<h5>${this.$t('activity')}: ${activitiesName}</h5>`
      }
      if (this.stateCardContainerModel.filters && this.stateCardContainerModel.filters[1].data[0]) {
        details += `<h5>${this.$t('type')}: ${typesName}</h5>`
      }
      return details
    },
    /**
     * Para deshabilitar los botones imprimir y exportar
     */
    btnDisabled () {
      return this.filteredPages[this.tabIndex] && this.filteredPages[this.tabIndex].data.length === 0
    }
  },
  methods: {
    /**
     * Esta función se ejecuta para obtener los datos de la carga inicial
     * y cuando cambian los filtros
     * @param {*} filters
     */
    async getData (filters) {
      configurationService.save(this._id,
        {
          activities: filters.filters[0].selectedData,
          types: filters.filters[1].selectedData
        })
      clearInterval(this.intervalId)
      await this.getDashboardData(filters)
      const that = this
      that.intervalId = setInterval(async function () {
        await that.updateAll()
      }, REFRESH * 60 * 1000)
    },
    /**
     * Función para obtener los datos de todos los niveles
     * @param {*} filters
     */
    async getDashboardData (filters) {
      this.loading = true

      // se guarda el estado de los filtros
      this.activities = filters.filters[0].selectedData
      // ids de actividadaes
      const activitiesId = this.activities.map(a => a.id)

      // request de tipos de cosas
      this.types = filters.filters[1].selectedData
      const typesKey = this.types.map(t => t.key)

      /*
      Request de frentes de trabajo según los tipos

      TO DO: implementar la selección de la máquina desde donde se obtendrán los datos (para frentes que no sean
        frentes de cosecha)

      Por ahora, sólo selecciono los pañoles que identifican a frentes de cosecha
      */
      this.workFrontsData = (await thingApi.getWorkFrontsByTypeKeys(typesKey)).data
      this.workFrontMainThings = this.getWorkFrontMainThings()

      // request para obtener los datos
      const dashboardResponse = await workFrontsStateApi.getWorkFrontsStateAll(
        this.sector, activitiesId, typesKey, this.workFrontMainThings.map(t => t.id))

      // se actualizan las páginas
      this.pages.cleanAndUpdate(dashboardResponse.data.pages)
      this.filteredPages = clone(this.pages)

      // constante para los selectores
      const all = {
        id: 'ALL',
        name: i18n.t('all')
      }

      // actualizo la lista de datos para los selectores
      if (dashboardResponse.data.workFronts.length > 1) {
        this.selectorsData[0].cleanAndUpdate([all].concat(dashboardResponse.data.workFronts))
      } else {
        this.selectorsData[0].cleanAndUpdate(dashboardResponse.data.workFronts)
      }
      if (dashboardResponse.data.machines.length > 1) {
        this.selectorsData[1].cleanAndUpdate([all].concat(dashboardResponse.data.machines))
      } else {
        this.selectorsData[1].cleanAndUpdate(dashboardResponse.data.machines)
      }

      // actualizo los datos del selector actual
      this.stateCardContainerModel.selectorData.cleanAndUpdate(this.selectorsData[this.tabIndex])
      // reinicio los valores seleccionados (null para cada uno)
      // this.selectorsModel.forEach(value => {
      //   value = null
      // })
      this.filterPages()

      this.loading = false
    },
    /**
     * Función para obtener los ids de las máquinas que represetan el estado de los frentes de trabajo.
     * Por ahora, sólo se devuelven los ids de los pañoles de los frentes de cosecha.
     *
     * TO DO: implementar para otros frentes de trabajo.
     */
    getWorkFrontMainThings () {
      let result = []
      this.workFrontsData.forEach(wf => {
        if (wf.children && Array.isArray(wf.children)) {
          result = result.concat(wf.children.filter(thing => thing.typeKey === 'STOREROOM'))
        }
      })
      return result
    },
    /**
     * Cuando se cambia de tab, se ejecuta esta función
     * @param {*} index
     * @param {*} id
     */
    tabChange (index) {
      // cambio el valor del selector y guardo el valor para el tab actual
      this.stateCardContainerModel.selectorData.cleanAndUpdate(this.selectorsData[index])
      // tabIndex aún tiene los valores del tab anterior, guardo el valor del selector
      this.selectorsModel[this.tabIndex] = this.stateCardContainerModel.selectorModel
      // si hay valor seleccionado, lo seteo
      this.stateCardContainerModel.selectorModel = this.selectorsModel[index]
      // al fnal de todo, actualizo el valor de tabIndex
      this.tabIndex = index
    },
    /**
     * Este método es ejecutado cuando se actualizan todas las vistas por el timer del "autoreporte"
     */
    async updateAll () {
      this.loading = true

      // request para obtener los datos
      const dashboardResponse = await workFrontsStateApi.getWorkFrontsStateAll(
        this.sector, this.activities.map(a => a.id), this.types.map(t => t.key), this.workFrontMainThings.map(t => t.id))

      // se actualizan las páginas
      this.pages.cleanAndUpdate(dashboardResponse.data.pages)
      this.filteredPages = clone(this.pages)

      // constante para los selectores
      const all = {
        id: 'ALL',
        name: i18n.t('all')
      }

      // actualizo la lista de datos para los selectores
      if (dashboardResponse.data.workFronts.length > 1) {
        this.selectorsData[0].cleanAndUpdate([all].concat(dashboardResponse.data.workFronts))
      } else {
        this.selectorsData[0].cleanAndUpdate(dashboardResponse.data.workFronts)
      }
      if (dashboardResponse.data.machines.length > 1) {
        this.selectorsData[1].cleanAndUpdate([all].concat(dashboardResponse.data.machines))
      } else {
        this.selectorsData[1].cleanAndUpdate(dashboardResponse.data.machines)
      }

      // actualizo los datos del selector actual
      this.stateCardContainerModel.selectorData.cleanAndUpdate(this.selectorsData[this.tabIndex])

      // verificación de valor seleccionado actual
      this.selectorsModel.forEach((value, index) => {
        if (!this.selectorsData[index].find(data => data.id === value)) {
          value = null
          if (index === this.tabIndex) {
            this.stateCardContainerModel.selectorModel = null
          }
        }
      })
      this.filterPages()

      this.loading = false
    },
    /**
     * Para validar el selector de filtros y habilitar/deshabilitar el botón para guardar
     */
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.stateCardContainerModel.filters[0].selectedData
        const typeSelected = that.stateCardContainerModel.filters[1].selectedData
        that.stateCardContainerModel.disableSaveButton =
        activitySelected.length === 0 || typeSelected.length === 0
      })
    },
    /**
     * Para filtrar las páginas según los valores seleccionados
     */
    filterPages () {
      const that = this
      Vue.nextTick(function () {
        const copy = clone(that.pages)
        if (that.stateCardContainerModel.selectorModel && that.stateCardContainerModel.selectorModel !== 'ALL') {
          that.filteredPages[that.tabIndex].data = copy[that.tabIndex].data.filter(card => card.id === that.stateCardContainerModel.selectorModel)
        } else {
          that.filteredPages[that.tabIndex].data = copy[that.tabIndex].data
        }
      })
    },
    async activityFilterCallback () {
      if (this.stateCardContainerModel.filters[0].selectedData.length > 0) {
        this.stateCardContainerModel.filters[1].selectedData = []
        this.stateCardContainerModel.filters[1].disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.stateCardContainerModel.filters[0].selectedData.map(a => a.id), this.sector, this.report)
        this.stateCardContainerModel.filters[1].data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.stateCardContainerModel.filters[1].selectedData = []
        this.stateCardContainerModel.filters[1].disabled = true
      }
    },
    /**
     * Para obtener la configuración de filtros
     * Esta función se ejecuta en mounted
     * @param {*} init
     * @returns
     */
    async getConfiguration (init) {
      let initialLoad = init
      const config = await configurationService.get(this._id)
      if (config) {
        // actividades
        if (this.stateCardContainerModel.filters[0].data.length > 0) {
          if (config.data.activities && config.data.activities.length > 0) {
            config.data.activities.forEach(activity => {
              if (this.stateCardContainerModel.filters[0].data.find(a => a.id === activity.id) && !this.stateCardContainerModel.filters[0].selectedData.find(a => a.id === activity.id)) {
                this.stateCardContainerModel.filters[0].selectedData.push(activity)
              }
            })
          }

          // tipos de equipamiento
          await this.activityFilterCallback()
          if (config.data.types && config.data.types.length > 0) {
            config.data.types.forEach(type => {
              if (this.stateCardContainerModel.filters[1].data.find(t => t.id === type.id) && !this.stateCardContainerModel.filters[1].selectedData.find(t => t.id === type.id)) {
                this.stateCardContainerModel.filters[1].selectedData.push(type)
              }
            })
          }
        } else {
          initialLoad = false
        }
      } else { // CARGA SIN CONFIG
        // para selector CON actividad
        if (this.stateCardContainerModel.filters[0].data.length > 1) {
          await this.activityFilterCallback()
          // para selector SIN actividad
        } else if (this.stateCardContainerModel.filters[0].data.length === 1 && this.stateCardContainerModel.filters[0].data[0].key === 'NO_ACTIVITY') {
          this.stateCardContainerModel.filters[0].selectedData.cleanAndUpdate([this.stateCardContainerModel.filters[0].data[0]])
        } else {
          initialLoad = false
        }
      }
      return initialLoad
    }
  },
  watch: {
    'stateCardContainerModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'stateCardContainerModel.selectorModel': {
      handler: function () {
        this.selectorsModel[this.tabIndex] = this.stateCardContainerModel.selectorModel
        this.filterPages()
      },
      deep: true
    }
  }
}
