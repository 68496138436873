import service from '@/middleware'

export const configurationApi = {
  /**
   * Este método save se usa en el servicio configurationService
   * @param {*} userId
   * @param {*} configuration
   */
  async save (userId, configuration) {
    const response = await service.http.put('/api/configuration/update/' + userId, configuration)
    return response.data
  },

  /**
   * Get de una configuración del usuario
   * @param {*} userId
   * @param {*} id
   */
  async get (userId, id) {
    const response = await service.http.get(`/api/configuration/one-by-id/${userId}/${id}`)
    return response.data
  }
}

export default configurationApi
