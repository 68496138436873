import UnitsInServiceComponent from '@/components/units-in-service/UnitsInServiceComponent.vue'
import UnitsStateComponent from '@/components/units-state/UnitsStateComponent.vue'
import WorkingAverageSpeedComponent from '@/components/working-average-speed/WorkingAverageSpeedComponent.vue'
import ProductiveTimeComponent from '@/components/productive-time/ProductiveTimeComponent.vue'
import WorkFrontsStateComponent from '@/components/work-fronts/WorkFrontsStateComponent.vue'
import EngineStateComponent from '@/components/engine-state/EngineStateComponent.vue'
// import { SECTOR_KEY } from '@colven/common-domain-lib/lib'

export default {
  name: 'FieldEngineeringDashboard',
  components: {
    UnitsInServiceComponent,
    UnitsStateComponent,
    WorkingAverageSpeedComponent,
    ProductiveTimeComponent,
    WorkFrontsStateComponent,
    EngineStateComponent
  },
  data: () => ({
    sector: 'FIELD_ENGINEERING'
    // unitsInServiceSector: SECTOR_KEY.UNITS_IN_SERVICE_FIELD_ENGINEERING,
    // unitsStateSector: SECTOR_KEY.UNITS_STATE_FIELD_ENGINEERING,
    // workingAverageSpeedSector: SECTOR_KEY.WORKING_AVERAGE_SPEED_FIELD_ENGINEERING,
    // productiveTimeSector: SECTOR_KEY.WORKING_AVERAGE_SPEED_FIELD_ENGINEERING,
    // workFrontsStateSector: SECTOR_KEY.WORK_FRONTS_FIELD_ENGINEERING,
    // engineStateSector: 'ENGINE_STATE_FIELD_ENGINEERING'
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
  }
}
