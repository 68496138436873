import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import { Dataset } from '@colven/common-domain-lib/lib'
import activityApi from '@/api/activity.api'
import unitsInServiceApi from '@/api/unitsInService.api'
import thingApi from '@/api/thing.api'
import { configurationService } from '@/business/configurationService'

// constante de update de gráficos (en minutos)
const REFRESH = 10

export default {
  name: 'UnitsInServiceComponent',
  components: {
    DashboardChartContainer
  },
  props: {
    chartComponentId: {
      type: String,
      required: true
    },
    sector: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },
  data: () => ({
    report: 'UNITS_IN_SERVICE',
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [],
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: null,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'type',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    // datos de los filtros seleccionados
    types: [],
    activities: [],
    farms: [],
    workFronts: [],
    currentChartIndex: 0,
    loading: false,
    farmSelectorModel: null,
    workFrontsSelectorModel: null,
    chartName: '',
    intervalId: null,
    // para guardar los datos del dashboard
    data: [],
    // para la opción "todos" de los selectores
    all: {
      id: 'ALL',
      name: i18n.t('all')
    }
  }),
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // constante para validar si cargar inicialmente los gráficos
    let initialLoad = true
    this.loading = true

    // actividades
    const activityResponse = await activityApi.getAllForSelector(this.sector, this.report)
    if (Array.isArray(activityResponse.data)) {
      this.chartContainerModel.filters[0].data.cleanAndUpdate(activityResponse.data)
      if (activityResponse.data.length === 1 && activityResponse.data[0].key === 'NO_ACTIVITY') {
        this.chartContainerModel.filters[0].selectedData = this.chartContainerModel.filters[0].data.filter(s => s.key === 'NO_ACTIVITY')
        this.chartContainerModel.filters[0].disabled = true
        this.chartContainerModel.filters[0].hide = true
        await this.activityFilterCallback()
      }
    }

    this.chartContainerModel.filters[0].selectAction = this.activityFilterCallback.bind(this)

    // configuración
    await this.getConfiguration(initialLoad)

    this.loading = false
    // ejecuto la validación del selector, si es correcta, se carga el gráfico, sino abro el selector
    if (initialLoad) {
      // creo los gráficos
      this.getData(this.chartContainerModel)
    } else {
      this.chartContainerModel.showNavigationPanel = true
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  computed: {
    details () {
      const activities = this.activities.map(a => a.name).toString()
      const types = this.types.map(t => t.name).toString()
      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('dashboard.equipmentsInService.title')}</h3>`
      if (this.chartName) {
        details += `<h5>${this.$t('level')}: ${this.chartName}</h5>`
      }
      if (this.chartContainerModel.filters && this.chartContainerModel.filters[0].data[0] && this.chartContainerModel.filters[0].data[0].key !== 'NO_ACTIVITY') {
        details += `<h5>${this.$t('activity')}: ${activities}</h5>`
      }
      if (this.chartContainerModel.filters && this.chartContainerModel.filters[1].data[0]) {
        details += `<h5>${this.$t('type')}: ${types}</h5>`
      }
      return details
    }
  },
  methods: {
    async getData (filters, update = false) {
      configurationService.save(this.chartComponentId,
        {
          activities: filters.filters[0].selectedData,
          types: filters.filters[1].selectedData
        })
      clearInterval(this.intervalId)
      await this.getDashboardData(filters, update)
      const that = this
      that.intervalId = setInterval(async function () {
        await that.updateAllCharts()
      }, REFRESH * 60 * 1000)
    },
    async getDashboardData (filters, update) {
      this.loading = true

      // guardo los datos de los tipos de equipos seleccionados
      this.types = Array.from(filters.filters[1].selectedData)
      const typesKeys = this.types.map(t => t.key)

      const dashboardResponse = await unitsInServiceApi.getUnitsInServiceAll(
        this.sector, typesKeys, this.chartContainerModel.percentageSwitchModel)
      this.chartContainerModel.charts.cleanAndUpdate(dashboardResponse.data.charts)

      this.data = dashboardResponse.data.data

      this.farms.cleanAndUpdate([this.all].concat(dashboardResponse.data.farms))
      this.workFronts.cleanAndUpdate([this.all].concat(dashboardResponse.data.workFronts))
      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      if (!this.farms.find(item => item.id === this.farmSelectorModel)) {
        this.farmSelectorModel = null
      }
      if (!this.workFronts.find(item => item.id === this.workFrontsSelectorModel)) {
        this.workFrontsSelectorModel = null
      }

      if (update) {
        // MODIFICACIÓN DE LOS FILTROS

        // si es una actualización (se modificaron los filtros, actualizo los datos del selector actual)
        switch (this.currentChartIndex) {
          case 0:
            this.chartContainerModel.selectorData.cleanAndUpdate(this.farms)
            this.chartContainerModel.selectorModel = this.farmSelectorModel
            break
          case 1:
            this.chartContainerModel.selectorData.cleanAndUpdate(this.workFronts)
            this.chartContainerModel.selectorModel = this.workFrontsSelectorModel
            break
          default:
            console.error('Gráfico no programado en el componente')
            break
        }

        // update
        this.chartContainerModel.update = true
        const that = this
        Vue.nextTick(function () {
          that.chartContainerModel.update = false
        })
      } else {
        // PRIMERA CARGA DE LOS GRÁFICOS
        this.chartContainerModel.selectorData.cleanAndUpdate(this.farms)
        this.chartName = this.chartContainerModel.charts[0].name
      }
      this.loading = false
    },
    /**
     * Cuando se cambia el switch de porcentaje, se ejecuta esta función
     * para cambiar los datasets y otras configs del gráfico
     */
    switchPercentage (percentage) {
      this.chartContainerModel.charts.forEach((chart) => {
        chart.timeDurationY = !percentage
        chart.options.scales.yAxes[0].scaleLabel.labelString = percentage
          ? this.$t('dashboard.percentage')
          : this.$t('dashboard.time')
      })
      this.filterCurrentChart(this.currentChartIndex, percentage)
    },
    /**
     * Para filtrar los datos del gráfico de acuerdo al selector
     * @param {*} index
     * @param {*} percentage
     */
    filterCurrentChart (index, percentage) {
      if (!this.chartContainerModel.selectorModel || this.chartContainerModel.selectorModel === 'ALL') {
        const { labels, datasets } = this.generateDatasets(percentage, null)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      } else {
        const { labels, datasets } = this.generateDatasets(percentage, this.chartContainerModel.selectorModel)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      }

      /*
        SOLUCIÓN PROVISORIA PARA LA ACTUALIZACIÓN DE GRÁFICOS:
        CUANDO SE IMPLEMETE EL MODELO EN EL COMPONENTE GENÉRICO, LA ACTUALIZACIÓN SE REALIZARÁ DE FORMA INMEDIATA
        CUANDO CAMBIEN LOS DATOS DEL GRÁFICO.
        Por ahora se definió un variable booleana del tipo "semáforo", es decir, cuando está en true, ejecuta la actualización
      */
      this.chartContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.chartContainerModel.update = false
      })
    },
    /**
     * Para generar los datasets cuando se filtra o se cambia de modo (porcentaje / tiempo)
     * @param {*} percentage
     * @param {*} id
     */
    generateDatasets (percentage, id) {
      const labels = []
      const datasetOutOfService = new Dataset()
      const datasetInService = new Dataset()
      datasetOutOfService.label = this.$t('dashboard.equipmentsInService.outOfService')
      datasetInService.label = this.$t('dashboard.equipmentsInService.inService')
      datasetOutOfService.backgroundColor = '#f44336'
      datasetOutOfService.borderColor = '#f44336'
      datasetInService.backgroundColor = '#2196F3'
      datasetInService.borderColor = '#2196F3'
      datasetOutOfService.formattedTooltipData.footer = []
      datasetInService.formattedTooltipData.footer = []
      const filteredData = id
        ? this.data[this.currentChartIndex].filter(d => d.id === id)
        : this.data[this.currentChartIndex]
      filteredData.forEach(d => {
        if (percentage) {
          datasetOutOfService.data.push(d.outOfServicePercentage.value)
          datasetOutOfService.formattedTooltipData.label.push(d.outOfServicePercentage.text)
          datasetOutOfService.formattedLabelData.push(d.outOfServicePercentage.text)
          datasetInService.data.push(d.inServicePercentage.value)
          datasetInService.formattedTooltipData.label.push(d.inServicePercentage.text)
          datasetInService.formattedLabelData.push(d.inServicePercentage.text)
        } else {
          datasetOutOfService.data.push(d.outOfService.value)
          datasetOutOfService.formattedTooltipData.label.push(d.outOfService.text)
          datasetOutOfService.formattedLabelData.push(d.outOfService.text)
          datasetInService.data.push(d.inService.value)
          datasetInService.formattedTooltipData.label.push(d.inService.text)
          datasetInService.formattedLabelData.push(d.inService.text)
        }
        // custom tooltips
        datasetOutOfService.formattedTooltipData.footer.push(d.outOfServiceFooter)
        datasetInService.formattedTooltipData.footer.push(d.inServiceFooter)
        // labels
        labels.push(d.name)
      })
      return { labels, datasets: [datasetOutOfService, datasetInService] }
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} data
     */
    changeChart (data) {
      if (data.index !== this.currentChartIndex) {
        // nombre del gráfico (para los detalles)
        this.chartName = this.chartContainerModel.charts[data.index].name
        // actualizo el valor de currentChart
        this.currentChartIndex = data.index

        switch (data.index) {
          case 0:
            this.chartContainerModel.selectorData.cleanAndUpdate(this.farms)
            this.workFrontsSelectorModel = this.chartContainerModel.selectorModel
            // actualización del gráfico
            if (this.chartContainerModel.selectorModel !== this.farmSelectorModel) {
              this.chartContainerModel.selectorModel = this.farmSelectorModel
            } else {
              this.updateChart()
            }
            break
          case 1:
            this.chartContainerModel.selectorData.cleanAndUpdate(this.workFronts)
            this.farmSelectorModel = this.chartContainerModel.selectorModel
            // actualización del gráfico
            if (this.chartContainerModel.selectorModel !== this.workFrontsSelectorModel) {
              this.chartContainerModel.selectorModel = this.workFrontsSelectorModel
            } else {
              this.updateChart()
            }
            break
        }
      }
    },
    /**
     * Para actualizar el gráfico actual cuando se usa el selector
     */
    async updateChart () {
      this.loading = true

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)

      this.loading = false
    },
    async updateAllCharts () {
      this.loading = true

      // GENERO LOS DATOS NUEVAMENTE
      const dashboardResponse = await unitsInServiceApi.getUnitsInServiceAll(
        this.sector, this.types.map(t => t.key), this.chartContainerModel.percentageSwitchModel)

      // datos
      this.data = dashboardResponse.data.data

      // actualizo la lista de datos para los selectores
      this.farms.cleanAndUpdate([this.all].concat(dashboardResponse.data.farms))
      this.workFronts.cleanAndUpdate([this.all].concat(dashboardResponse.data.workFronts))
      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      if (!this.farms.find(item => item.id === this.farmSelectorModel)) {
        this.farmSelectorModel = null
      }
      if (!this.workFronts.find(item => item.id === this.workFrontsSelectorModel)) {
        this.workFrontsSelectorModel = null
      }

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)
      this.loading = false
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.chartContainerModel.filters[0].selectedData
        const typeSelected = that.chartContainerModel.filters[1].selectedData
        that.chartContainerModel.disableSaveButton =
        activitySelected.length === 0 || typeSelected.length === 0
      })
    },
    async activityFilterCallback () {
      if (this.chartContainerModel.filters[0].selectedData.length > 0) {
        this.chartContainerModel.filters[1].selectedData = []
        this.chartContainerModel.filters[1].disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.chartContainerModel.filters[0].selectedData.map(a => a.id), this.sector, this.report)
        this.chartContainerModel.filters[1].data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.chartContainerModel.filters[1].selectedData = []
        this.chartContainerModel.filters[1].disabled = true
      }
    },
    /**
     * Para obtener la configuración de filtros
     * Esta función se ejecuta en mounted
     * @param {*} init
     * @returns
     */
    async getConfiguration (init) {
      let initialLoad = init
      const config = await configurationService.get(this.chartComponentId)
      if (config) {
        // actividades
        if (this.chartContainerModel.filters[0].data.length > 0) {
          if (config.data.activities && config.data.activities.length > 0) {
            config.data.activities.forEach(activity => {
              if (this.chartContainerModel.filters[0].data.find(a => a.id === activity.id) && !this.chartContainerModel.filters[0].selectedData.find(a => a.id === activity.id)) {
                this.chartContainerModel.filters[0].selectedData.push(activity)
              }
            })
          }

          await this.activityFilterCallback()
          if (config.data.types && config.data.types.length > 0) {
            config.data.types.forEach(type => {
              if (this.chartContainerModel.filters[1].data.find(t => t.id === type.id) && !this.chartContainerModel.filters[1].selectedData.find(t => t.id === type.id)) {
                this.chartContainerModel.filters[1].selectedData.push(type)
              }
            })
          }
        } else {
          initialLoad = false
        }
      } else { // CARGA SIN CONFIG
        // para selector CON actividad
        if (this.chartContainerModel.filters[0].data.length > 1) {
          await this.activityFilterCallback()
          // para selector SIN actividad
        } else if (this.chartContainerModel.filters[0].data.length === 1 && this.chartContainerModel.filters[0].data[0].key === 'NO_ACTIVITY') {
          this.chartContainerModel.filters[0].selectedData.cleanAndUpdate([this.chartContainerModel.filters[0].data[0]])
        } else {
          initialLoad = false
        }
      }
      return initialLoad
    }
  },
  watch: {
    'chartContainerModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.selectorModel': {
      handler: function () {
        this.updateChart()
        switch (this.currentChartIndex) {
          case 0:
            this.farmSelectorModel = this.chartContainerModel.selectorModel
            break
          case 1:
            this.workFrontsSelectorModel = this.chartContainerModel.selectorModel
            break
          default:
            console.error('Gráfico no programado en el componente')
            break
        }
      },
      deep: true
    },
    'chartContainerModel.percentageSwitchModel': {
      handler: function () {
        this.updateChart()
      },
      deep: true
    }
  }
}
