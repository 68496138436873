import service from '@/middleware'

const productiveTimeApi = {
  // los dos gráficos
  async getProductiveTimeAll (sector, types, percentage, from, to, timeRange) {
    const body = {
      sector,
      types,
      percentage,
      from,
      to,
      timeRange
    }
    const result = await service.http.post('/api/productive-time/all', body)
    return result
  }
}

export default productiveTimeApi
