import service from '@/middleware'

const unitsInServiceApi = {
  // los dos gráficos
  async getUnitsInServiceAll (sector, types, percentage) {
    const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
    const body = {
      sector,
      types,
      percentage,
      enterpriseId
    }
    const result = await service.http.post('/api/units-in-service/all', body)
    return result
  }
}

export default unitsInServiceApi
