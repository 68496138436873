export default {
  name: 'EngineStateCardComponent',
  components: {
  },
  props: {
    thing: {
      type: Object,
      required: true
    }
  },
  data: () => ({
  }),
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  created () {
  },
  beforeDestroy () {
  },
  computed: {
  },
  methods: {
    /**
     * Para verificar si un valor es null o undefined
     * @param {*} value
     */
    checkUndefined (value) {
      const result = value == null
      return result
    }
  },
  watch: {
  }
}
