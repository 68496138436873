import service from '@/middleware'

const workingAverageSpeedApi = {
  // los dos gráficos
  async getWorkingAverageSpeedAll (sector, types, from, to, timeRange) {
    const body = {
      sector,
      types,
      from,
      to,
      timeRange
    }
    const result = await service.http.post('/api/working-average-speed/all', body)
    return result
  }
}

export default workingAverageSpeedApi
