export default {
  name: 'NoDataAvailableComponent',
  components: {
  },
  /*
    model: {
        text: texto que se muestra (no es obligatorio)
    }
  */
  model: {
    prop: 'model'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
  }),
  async mounted () {
  },
  created () {
  },
  beforeDestroy () {
  },
  computed: {
  },
  methods: {
  }
}
