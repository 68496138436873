/* eslint-disable indent */
import { Dataset } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

/**
 * Para generar los datasets cuando se filtra o se cambia de modo (porcentaje / tiempo)
 * @param {*} percentage
 * @param {*} id
 */
const generateDatasets = (data, percentage, id) => {
    const labels = []
    const productiveDataset = new Dataset()
    const unproductiveDataset = new Dataset()
    productiveDataset.label = i18n.t('dashboard.productive')
    unproductiveDataset.label = i18n.t('dashboard.unproductive')
    productiveDataset.backgroundColor = '#4CAF50'
    productiveDataset.borderColor = '#4CAF50'
    unproductiveDataset.backgroundColor = '#F44336'
    unproductiveDataset.borderColor = '#F44336'
    const filteredData = id
        ? data.filter(d => d.id === id)
        : data
    filteredData.forEach(element => {
        if (percentage) {
            // data productivo
            productiveDataset.data.push(element.productivePercentage.value)
            productiveDataset.formattedTooltipData.label.push(element.productivePercentage.text)
            productiveDataset.formattedLabelData.push(element.productivePercentage.text)
            // data improductivo
            unproductiveDataset.data.push(element.unproductivePercentage.value)
            unproductiveDataset.formattedTooltipData.label.push(element.unproductivePercentage.text)
            unproductiveDataset.formattedLabelData.push(element.unproductivePercentage.text)
        } else {
            // data productivo
            productiveDataset.data.push(element.productive.value)
            productiveDataset.formattedTooltipData.label.push(element.productive.text)
            productiveDataset.formattedLabelData.push(element.productive.text)
            // data improductivo
            unproductiveDataset.data.push(element.unproductive.value)
            unproductiveDataset.formattedTooltipData.label.push(element.unproductive.text)
            unproductiveDataset.formattedLabelData.push(element.unproductive.text)
        }
        // labels
        labels.push(element.name)
    })
    return { labels, datasets: [productiveDataset, unproductiveDataset] }
}

export default {
    generateDatasets
}
