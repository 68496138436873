import service from '@/middleware'

const workFrontsStateApi = {
  // los dos niveles (frentes y máquinas)
  async getWorkFrontsStateAll (sector, activities, types, workFrontMainThings) {
    const body = {
      sector,
      activities,
      types,
      workFrontMainThings
    }
    const result = await service.http.post('/api/work-fronts-state/all', body)
    return result
  }
}

export default workFrontsStateApi
